@import url(https://use.fontawesome.com/releases/v5.8.1/css/all.css);
@charset "UTF-8";
/**
*   style.scss
*   @description: Constructor de la hoja de estilos
*   @variables: dev: boolean: Activa las opciones de desarrollo
*/
/*  
*   1.- Core Styles: trejocode.css
*   2.- Componentes: Todos los elementos posibles
*/
/*      Core       */
/**
*   dev.scss
*   @description: Todas las opciones de desarrollo
*/
/*  INFORMATION
   =============================
    
	- File:           trejocode.css
	- Versión:        1.2
	- Licence:        Mozilla Public Licence. 
	- Author:         TrejoCode
    - Update:         11/06/2019
    - Client:         iGate
*/
/* RESET
   =============================
   - These properties able the cross compatibility for browsers and removes default properties from browsers.
*/
* {
  padding: 0;
  margin: 0; }

html {
  font-size: 100%; }

*, *:before, *:after {
  box-sizing: border-box; }

body {
  overflow-x: hidden;
  background-color: rgba(234, 84, 95, 0.05); }

input, textarea, select, option, button {
  margin: 0;
  border: 0;
  padding: 0;
  outline: none; }

table {
  border-spacing: 0;
  overflow-x: auto; }

table th {
  white-space: nowrap; }

input:focus, select:focus {
  outline: none;
  -webkit-transition: 400ms ease-in-out;
  transition: 400ms ease-in-out; }

ul, ol {
  list-style: none; }

a {
  color: inherit;
  word-break: keep-all;
  text-decoration: none;
  align-self: flex-start; }

img {
  max-width: 100%;
  height: auto; }

/* CLASES
   =============================
	1. = FLEX LAYOUT
	2. = ALIGNMENT
    3. = WHITE SPACES
    4. = TABLES
    5. = IMAGES
    6. = DECORATORS & COLORS
*/
/*    FLEX LAYOUT
    - This stylesheet is based on flexbox.
    - W3 CSS Flexible Box Layout Module:
       https://www.w3.org/TR/css-flexbox-1
    
*/
.flex {
  width: 100%;
  display: flex; }

.container {
  width: 90%;
  display: flex; }

.responsive-img {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start; }

.row {
  display: flex;
  flex-direction: row; }

.row-responsive {
  display: flex;
  flex-direction: row; }

.column {
  display: flex;
  flex-direction: column; }

.wrap {
  flex-wrap: wrap;
  display: flex; }

/*    ALIGNMENT    */
.justify-center {
  width: 100%;
  display: flex;
  justify-content: center; }

.justify-start {
  width: 100%;
  display: flex;
  justify-content: flex-start; }

.justify-end {
  width: 100%;
  display: flex;
  justify-content: flex-end; }

.justify-between {
  width: 100%;
  display: flex;
  justify-content: space-between; }

.justify-around {
  width: 100%;
  display: flex;
  justify-content: space-around; }

.align-center {
  width: 100%;
  display: flex;
  align-items: center; }

.align-start {
  width: 100%;
  display: flex;
  align-items: flex-start; }

.align-end {
  width: 100%;
  display: flex;
  align-items: flex-end; }

.auto {
  width: auto;
  display: flex; }

.full {
  width: 100%; }

/*    TEXT ALIGNMENT    */
.text-left {
  text-align: left; }

.text-center {
  text-align: center; }

.text-justify {
  text-align: justify; }

.text-right {
  text-align: right; }

/*    TABLES    */
.table-responsive {
  overflow-x: auto; }

.table-responsive table {
  width: 100%; }

.table-auto {
  table-layout: auto; }

.table-equal {
  table-layout: fixed; }

/*    IMAGES    */
.cover-img {
  object-fit: cover; }

.contain-img {
  object-fit: contain; }

/*    DECORATORS    */
.hidden {
  display: none !important; }

.cursor-pointer {
  cursor: pointer; }

/*    LARGE DEVICES    */
@media screen and (min-width: 1440px) {
  .container {
    max-width: 1440px; } }

/*    NOTEBOOKS, IPADS DEVICES    */
/*    TABLETS DEVICES    */
@media screen and (max-width: 768px) {
  .row-responsive {
    flex-direction: column; }
  .white-space-64 {
    height: 32px; } }

/*    MOBILE DEVICES    */
@media screen and (max-width: 576px) {
  .container {
    width: 92%; } }

/**
*   colors.scss
*   @description: Variables de colores
*/
/* Classes de colores */
.color-white {
  color: #FFFFFF; }

.color-black {
  color: #000000; }

.color-dark {
  color: #4e4a4a; }

.color-primary {
  color: #EA545F; }

.color-secondary {
  color: #6c757d; }

.color-success {
  color: #28a745; }

.color-info {
  color: #17a2b8; }

.color-warning {
  color: #ffc107; }

.color-danger {
  color: #ea4235; }

/**
*   fonts.scss
*   @description: Estilos de fuente y tipografía
*/
/*    Font families    */
/*    Font sizes    */
.font-double {
  font-size: 2rem; }

.font-huge {
  font-size: 1.8rem; }

.font-large {
  font-size: 1.6rem; }

.font-medium {
  font-size: 1.4rem; }

.font-regular {
  font-size: 1.2rem; }

.font-small {
  font-size: 1rem; }

.font-text {
  font-size: 0.93rem; }

.font-tiny {
  font-size: 0.9rem; }

.font-mini {
  font-size: 0.8rem; }

/*    Font weight   */
.weight-bold {
  font-weight: 700; }

.weight-semi {
  font-weight: 600; }

.weight-medium {
  font-weight: 500; }

.weight-regular {
  font-weight: 400; }

a {
  font-family: "Mukta", sans-serif;
  font-size: 1rem; }

li {
  font-family: "Mukta", sans-serif;
  font-size: 1rem; }

h1,
h2,
h3,
h4,
h5,
h6,
p,
b,
span,
td,
td,
tr {
  color: #4e4a4a;
  font-family: "Mukta", sans-serif; }

h1 {
  font-size: 1.8rem;
  font-weight: 700; }

h2 {
  font-size: 1.6rem;
  font-weight: 700; }

h3 {
  font-size: 1.4rem;
  font-weight: 600; }

h4 {
  font-size: 1.2rem;
  font-weight: 500; }

h5 {
  font-size: 0.93rem;
  font-weight: 500; }

h6 {
  font-size: 1.4rem;
  font-weight: 400; }

p,
b,
span {
  font-size: 0.93rem;
  line-height: 22px;
  color: #000000; }

/*   Decorators    */
.uppercase {
  text-transform: uppercase; }

.lowercase {
  text-transform: lowercase; }

.capitalize {
  text-transform: capitalize; }

.underline {
  text-decoration: underline; }

.line-through {
  text-decoration: line-through; }

::-moz-selection {
  color: #FFFFFF;
  background-color: #000000; }

::selection {
  color: #FFFFFF;
  background-color: #000000; }

/**
*   spacing.scss
*   @description: Espacios en blanco, margenes y paddings
*/
/*      Paddings       */
/*      Margins       */
/*      Whitespaces       */
.white-space-4 {
  height: 4px; }

.white-space-8 {
  height: 8px; }

.white-space-16 {
  height: 16px; }

.white-space-24 {
  height: 24px; }

.white-space-32 {
  height: 32px; }

.white-space-48 {
  height: 48px; }

.white-space-64 {
  height: 64px; }

/*      Componentes      */
/**
*   button.scss
*   @description: Estilos para los botones
*/
button {
  cursor: pointer;
  font-family: "Mukta", sans-serif;
  background-color: transparent; }
  button:disabled {
    cursor: not-allowed; }

.btn {
  cursor: pointer;
  font-family: "Mukta", sans-serif;
  font-size: 1rem;
  font-weight: 600;
  background-color: transparent;
  padding: 16px 24px;
  -webkit-transition: all 200ms ease-in-out;
  transition: all 200ms ease-in-out; }
  .btn:active {
    -webkit-transform: scale(0.95);
            transform: scale(0.95); }

.btn-mini {
  padding: 2px 7px;
  font-size: .8rem; }

.btn-medium {
  width: 50%; }

.btn-full {
  width: 100% !important; }

.btn-primary {
  background-color: #EA545F; }
  .btn-primary:hover {
    background-color: rgba(234, 84, 95, 0.8); }

.btn-secondary {
  background-color: #6c757d; }
  .btn-secondary:hover {
    background-color: rgba(108, 117, 125, 0.8); }

.btn-small {
  padding: 8px 16px; }

/**
*   inputs.scss
*   @description: Estilos para los inputs
*/
input, select, option, textarea {
  font-family: "Mukta", sans-serif; }

select:disabled {
  background-color: #EBEBE4; }

.input {
  color: #4e4a4a;
  padding: 8px;
  border-bottom: 2px solid rgba(108, 117, 125, 0.5); }
  .input:focus {
    border-bottom: 2px solid #EA545F; }

.input-select {
  border-radius: 4px;
  padding: 8px;
  font-weight: 600; }

.incidents .input-select, .guests .input-select {
  width: 200px;
  margin-left: 16px; }

.textarea {
  resize: none;
  line-height: 24px;
  padding: 8px;
  background-color: #f2f2f3; }

.radio {
  top: 0;
  border: 0;
  height: 20px;
  width: 20px;
  outline: none;
  cursor: pointer;
  border-radius: 50px;
  color: #666666;
  background-color: #f1f1f1;
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  display: inline-block;
  position: relative;
  margin-right: 16px;
  -webkit-transition: all 300ms ease-in-out;
  transition: all 300ms ease-in-out; }

.radio:checked::before {
  left: 6px;
  font-size: 0.8rem;
  content: '\02143';
  position: absolute;
  -webkit-transform: rotate(40deg);
          transform: rotate(40deg); }

.radio:hover {
  background-color: #dedede; }

.radio:checked {
  color: #FFFFFF;
  background-color: #EA545F; }

.radio-label {
  color: #4e4a4a;
  cursor: pointer;
  font-family: "Mukta", sans-serif;
  font-size: 1rem;
  margin-right: 16px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.input-modals {
  width: 100%;
  padding: 4px 2px; }

.input-modals-small {
  width: 40%;
  padding: 4px 2px; }

.modal-incidents #incident-resolution {
  border-radius: 0;
  background-color: #f2f2f3; }

.modal-houses .input {
  width: 100%;
  margin-left: 8px; }

/**
*   header.scss
*   @description: Estilos del Header
*/
header {
  padding: 16px;
  background-color: #000; }
  header img {
    width: 350px; }
  header .nav-item {
    padding: 0 8px;
    border-bottom: 2px solid transparent;
    -webkit-transition: border-bottom 300ms ease-in-out;
    transition: border-bottom 300ms ease-in-out;
    margin-left: 8px;
    padding: 4px 4px; }
    header .nav-item:hover {
      border-bottom: 2px solid #FFFFFF; }
    header .nav-item i {
      padding-right: 4px; }
  header .nav-active {
    border-bottom: 2px solid #C1272D;
    margin-left: 8px;
    padding: 4px 4px; }

.header-modals {
  padding: 8px 8px 8px 16px;
  border-radius: 4px 4px 0 0;
  background-color: #000000;
  width: 100%;
  position: relative; }
  .header-modals .btn-modal-close {
    position: absolute;
    right: 16px;
    top: 10px;
    color: white;
    font-size: 1.2rem; }
    .header-modals .btn-modal-close:hover {
      color: #EA545F;
      -webkit-transition: color 300ms ease-in-out;
      transition: color 300ms ease-in-out; }

.header-slide .logo {
  width: 26%; }

.header-slide .options .icon-main {
  color: #FFFFFF;
  -webkit-transition: color .300s ease-in-out;
  transition: color .300s ease-in-out;
  font-size: 1.2rem; }
  .header-slide .options .icon-main:hover {
    color: #C1272D; }

.header-slide .options .select-options {
  position: relative;
  margin-left: 16px;
  width: 250px;
  /* Remove IE arrow */
  /* Custom Select */
  /* Arrow */ }
  .header-slide .options .select-options:last-child {
    width: 200px; }
  .header-slide .options .select-options select {
    -webkit-appearance: none;
    -moz-appearance: none;
    -ms-appearance: none;
    appearance: none;
    padding: 0 .5em;
    color: #000000;
    cursor: pointer; }
  .header-slide .options .select-options select::-ms-expand {
    display: none; }
  .header-slide .options .select-options .select {
    position: relative;
    display: flex;
    width: 100%;
    height: 2.5em;
    line-height: 3;
    background: #FFFFFF;
    overflow: hidden;
    border-radius: 4px; }
  .header-slide .options .select-options .select-arrow {
    position: absolute;
    z-index: 2;
    top: 4px;
    right: 12px;
    border-radius: 0 4px 4px 0;
    height: 100%;
    font-size: 1.2rem;
    cursor: pointer;
    pointer-events: none;
    -webkit-transition: .25s all ease;
    transition: .25s all ease;
    color: black; }
  .header-slide .options .select-options:hover .select-arrow {
    color: #C1272D; }

/*      Libs     */
.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle, .react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle, .react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow {
  margin-left: -8px;
  position: absolute; }

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle, .react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle, .react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow, .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before, .react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle::before, .react-datepicker__year-read-view--down-arrow::before,
.react-datepicker__month-read-view--down-arrow::before,
.react-datepicker__month-year-read-view--down-arrow::before {
  box-sizing: content-box;
  position: absolute;
  border: 8px solid transparent;
  height: 0;
  width: 1px; }

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before, .react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle::before, .react-datepicker__year-read-view--down-arrow::before,
.react-datepicker__month-read-view--down-arrow::before,
.react-datepicker__month-year-read-view--down-arrow::before {
  content: "";
  z-index: -1;
  border-width: 8px;
  left: -8px;
  border-bottom-color: #aeaeae; }

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle {
  top: 0;
  margin-top: -8px; }

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle, .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before {
  border-top: none;
  border-bottom-color: #f0f0f0; }

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before {
  top: -1px;
  border-bottom-color: #aeaeae; }

.react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle, .react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow {
  bottom: 0;
  margin-bottom: -8px; }

.react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle, .react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow, .react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle::before, .react-datepicker__year-read-view--down-arrow::before,
.react-datepicker__month-read-view--down-arrow::before,
.react-datepicker__month-year-read-view--down-arrow::before {
  border-bottom: none;
  border-top-color: #fff; }

.react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle::before, .react-datepicker__year-read-view--down-arrow::before,
.react-datepicker__month-read-view--down-arrow::before,
.react-datepicker__month-year-read-view--down-arrow::before {
  bottom: -1px;
  border-top-color: #aeaeae; }

.react-datepicker-wrapper {
  width: 100%;
  display: inline-block; }

.react-datepicker {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 0.8rem;
  background-color: #fff;
  color: #000;
  border: 1px solid #aeaeae;
  border-radius: 0.3rem;
  display: inline-block;
  position: relative; }

.react-datepicker--time-only .react-datepicker__triangle {
  left: 35px; }

.react-datepicker--time-only .react-datepicker__time-container {
  border-left: 0; }

.react-datepicker--time-only .react-datepicker__time {
  border-radius: 0.3rem; }

.react-datepicker--time-only .react-datepicker__time-box {
  border-radius: 0.3rem; }

.react-datepicker__triangle {
  position: absolute;
  left: 50px; }

.react-datepicker-popper {
  z-index: 1; }

.react-datepicker-popper[data-placement^="bottom"] {
  margin-top: 10px; }

.react-datepicker-popper[data-placement^="top"] {
  margin-bottom: 10px; }

.react-datepicker-popper[data-placement^="right"] {
  margin-left: 8px; }

.react-datepicker-popper[data-placement^="right"] .react-datepicker__triangle {
  left: auto;
  right: 42px; }

.react-datepicker-popper[data-placement^="left"] {
  margin-right: 8px; }

.react-datepicker-popper[data-placement^="left"] .react-datepicker__triangle {
  left: 42px;
  right: auto; }

.react-datepicker__header {
  text-align: center;
  background-color: #f0f0f0;
  border-bottom: 1px solid #aeaeae;
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem;
  padding-top: 8px;
  position: relative; }

.react-datepicker__header--time {
  padding-bottom: 8px;
  padding-left: 5px;
  padding-right: 5px; }

.react-datepicker__year-dropdown-container--select,
.react-datepicker__month-dropdown-container--select,
.react-datepicker__month-year-dropdown-container--select,
.react-datepicker__year-dropdown-container--scroll,
.react-datepicker__month-dropdown-container--scroll,
.react-datepicker__month-year-dropdown-container--scroll {
  display: inline-block;
  margin: 0 2px; }

.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header {
  margin-top: 0;
  color: #000;
  font-weight: bold;
  font-size: 0.944rem; }

.react-datepicker-time__header {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden; }

.react-datepicker__navigation {
  background: none;
  line-height: 1.7rem;
  text-align: center;
  cursor: pointer;
  position: absolute;
  top: 10px;
  width: 0;
  padding: 0;
  border: 0.45rem solid transparent;
  z-index: 1;
  height: 10px;
  width: 10px;
  text-indent: -999em;
  overflow: hidden; }

.react-datepicker__navigation--previous {
  left: 10px;
  border-right-color: #ccc; }

.react-datepicker__navigation--previous:hover {
  border-right-color: #b3b3b3; }

.react-datepicker__navigation--previous--disabled, .react-datepicker__navigation--previous--disabled:hover {
  border-right-color: #e6e6e6;
  cursor: default; }

.react-datepicker__navigation--next {
  right: 10px;
  border-left-color: #ccc; }

.react-datepicker__navigation--next--with-time:not(.react-datepicker__navigation--next--with-today-button) {
  right: 80px; }

.react-datepicker__navigation--next:hover {
  border-left-color: #b3b3b3; }

.react-datepicker__navigation--next--disabled, .react-datepicker__navigation--next--disabled:hover {
  border-left-color: #e6e6e6;
  cursor: default; }

.react-datepicker__navigation--years {
  position: relative;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto; }

.react-datepicker__navigation--years-previous {
  top: 4px;
  border-top-color: #ccc; }

.react-datepicker__navigation--years-previous:hover {
  border-top-color: #b3b3b3; }

.react-datepicker__navigation--years-upcoming {
  top: -4px;
  border-bottom-color: #ccc; }

.react-datepicker__navigation--years-upcoming:hover {
  border-bottom-color: #b3b3b3; }

.react-datepicker__month-container {
  float: left; }

.react-datepicker__month {
  margin: 0.4rem;
  text-align: center; }

.react-datepicker__month .react-datepicker__month-text {
  display: inline-block;
  width: 4rem;
  margin: 2px; }

.react-datepicker__input-time-container {
  clear: both;
  width: 100%;
  float: left;
  margin: 5px 0 10px 15px;
  text-align: left; }

.react-datepicker__input-time-container .react-datepicker-time__caption {
  display: inline-block; }

.react-datepicker__input-time-container .react-datepicker-time__input-container {
  display: inline-block; }

.react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input {
  display: inline-block;
  margin-left: 10px; }

.react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input input {
  width: 85px; }

.react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input input[type="time"]::-webkit-inner-spin-button,
.react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input input[type="time"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0; }

.react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input input[type="time"] {
  -moz-appearance: textfield; }

.react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__delimiter {
  margin-left: 5px;
  display: inline-block; }

.react-datepicker__time-container {
  float: right;
  border-left: 1px solid #aeaeae;
  width: 70px; }

.react-datepicker__time-container--with-today-button {
  display: inline;
  border: 1px solid #aeaeae;
  border-radius: 0.3rem;
  position: absolute;
  right: -72px;
  top: 0; }

.react-datepicker__time-container .react-datepicker__time {
  position: relative;
  background: white; }

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box {
  width: 70px;
  overflow-x: hidden;
  margin: 0 auto;
  text-align: center; }

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list {
  list-style: none;
  margin: 0;
  height: calc(195px + (1.7rem / 2));
  overflow-y: scroll;
  padding-right: 0px;
  padding-left: 0px;
  width: 100%;
  box-sizing: content-box; }

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item {
  height: 30px;
  padding: 5px 10px; }

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item:hover {
  cursor: pointer;
  background-color: #f0f0f0; }

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected {
  background-color: #216ba5;
  color: white;
  font-weight: bold; }

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected:hover {
  background-color: #216ba5; }

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--disabled {
  color: #ccc; }

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--disabled:hover {
  cursor: default;
  background-color: transparent; }

.react-datepicker__week-number {
  color: #ccc;
  display: inline-block;
  width: 1.7rem;
  line-height: 1.7rem;
  text-align: center;
  margin: 0.166rem; }

.react-datepicker__week-number.react-datepicker__week-number--clickable {
  cursor: pointer; }

.react-datepicker__week-number.react-datepicker__week-number--clickable:hover {
  border-radius: 0.3rem;
  background-color: #f0f0f0; }

.react-datepicker__day-names,
.react-datepicker__week {
  white-space: nowrap; }

.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
  color: #000;
  display: inline-block;
  width: 1.7rem;
  line-height: 1.7rem;
  text-align: center;
  margin: 0.166rem; }

.react-datepicker__month--selected, .react-datepicker__month--in-selecting-range, .react-datepicker__month--in-range {
  border-radius: 0.3rem;
  background-color: #216ba5;
  color: #fff; }

.react-datepicker__month--selected:hover, .react-datepicker__month--in-selecting-range:hover, .react-datepicker__month--in-range:hover {
  background-color: #1d5d90; }

.react-datepicker__month--disabled {
  color: #ccc;
  pointer-events: none; }

.react-datepicker__month--disabled:hover {
  cursor: default;
  background-color: transparent; }

.react-datepicker__day,
.react-datepicker__month-text {
  cursor: pointer; }

.react-datepicker__day:hover,
.react-datepicker__month-text:hover {
  border-radius: 0.3rem;
  background-color: #f0f0f0; }

.react-datepicker__day--today,
.react-datepicker__month-text--today {
  font-weight: bold; }

.react-datepicker__day--highlighted,
.react-datepicker__month-text--highlighted {
  border-radius: 0.3rem;
  background-color: #3dcc4a;
  color: #fff; }

.react-datepicker__day--highlighted:hover,
.react-datepicker__month-text--highlighted:hover {
  background-color: #32be3f; }

.react-datepicker__day--highlighted-custom-1,
.react-datepicker__month-text--highlighted-custom-1 {
  color: magenta; }

.react-datepicker__day--highlighted-custom-2,
.react-datepicker__month-text--highlighted-custom-2 {
  color: green; }

.react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range {
  border-radius: 0.3rem;
  background-color: #216ba5;
  color: #fff; }

.react-datepicker__day--selected:hover, .react-datepicker__day--in-selecting-range:hover, .react-datepicker__day--in-range:hover,
.react-datepicker__month-text--selected:hover,
.react-datepicker__month-text--in-selecting-range:hover,
.react-datepicker__month-text--in-range:hover {
  background-color: #1d5d90; }

.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected {
  border-radius: 0.3rem;
  background-color: #2a87d0;
  color: #fff; }

.react-datepicker__day--keyboard-selected:hover,
.react-datepicker__month-text--keyboard-selected:hover {
  background-color: #1d5d90; }

.react-datepicker__day--in-selecting-range,
.react-datepicker__month-text--in-selecting-range {
  background-color: rgba(33, 107, 165, 0.5); }

.react-datepicker__month--selecting-range .react-datepicker__day--in-range, .react-datepicker__month--selecting-range
.react-datepicker__month-text--in-range {
  background-color: #f0f0f0;
  color: #000; }

.react-datepicker__day--disabled,
.react-datepicker__month-text--disabled {
  cursor: default;
  color: #ccc; }

.react-datepicker__day--disabled:hover,
.react-datepicker__month-text--disabled:hover {
  background-color: transparent; }

.react-datepicker__month-text.react-datepicker__month--selected:hover, .react-datepicker__month-text.react-datepicker__month--in-range:hover {
  background-color: #216ba5; }

.react-datepicker__month-text:hover {
  background-color: #f0f0f0; }

.react-datepicker__input-container {
  width: 100%;
  position: relative;
  display: inline-block; }

.react-datepicker__year-read-view,
.react-datepicker__month-read-view,
.react-datepicker__month-year-read-view {
  border: 1px solid transparent;
  border-radius: 0.3rem; }

.react-datepicker__year-read-view:hover,
.react-datepicker__month-read-view:hover,
.react-datepicker__month-year-read-view:hover {
  cursor: pointer; }

.react-datepicker__year-read-view:hover .react-datepicker__year-read-view--down-arrow,
.react-datepicker__year-read-view:hover .react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-read-view:hover .react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view:hover .react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view:hover .react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-year-read-view:hover .react-datepicker__month-read-view--down-arrow {
  border-top-color: #b3b3b3; }

.react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow {
  border-top-color: #ccc;
  float: right;
  margin-left: 20px;
  top: 8px;
  position: relative;
  border-width: 0.45rem; }

.react-datepicker__year-dropdown,
.react-datepicker__month-dropdown,
.react-datepicker__month-year-dropdown {
  background-color: #f0f0f0;
  position: absolute;
  width: 50%;
  left: 25%;
  top: 30px;
  z-index: 1;
  text-align: center;
  border-radius: 0.3rem;
  border: 1px solid #aeaeae; }

.react-datepicker__year-dropdown:hover,
.react-datepicker__month-dropdown:hover,
.react-datepicker__month-year-dropdown:hover {
  cursor: pointer; }

.react-datepicker__year-dropdown--scrollable,
.react-datepicker__month-dropdown--scrollable,
.react-datepicker__month-year-dropdown--scrollable {
  height: 150px;
  overflow-y: scroll; }

.react-datepicker__year-option,
.react-datepicker__month-option,
.react-datepicker__month-year-option {
  line-height: 20px;
  width: 100%;
  display: block;
  margin-left: auto;
  margin-right: auto; }

.react-datepicker__year-option:first-of-type,
.react-datepicker__month-option:first-of-type,
.react-datepicker__month-year-option:first-of-type {
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem; }

.react-datepicker__year-option:last-of-type,
.react-datepicker__month-option:last-of-type,
.react-datepicker__month-year-option:last-of-type {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border-bottom-left-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem; }

.react-datepicker__year-option:hover,
.react-datepicker__month-option:hover,
.react-datepicker__month-year-option:hover {
  background-color: #ccc; }

.react-datepicker__year-option:hover .react-datepicker__navigation--years-upcoming,
.react-datepicker__month-option:hover .react-datepicker__navigation--years-upcoming,
.react-datepicker__month-year-option:hover .react-datepicker__navigation--years-upcoming {
  border-bottom-color: #b3b3b3; }

.react-datepicker__year-option:hover .react-datepicker__navigation--years-previous,
.react-datepicker__month-option:hover .react-datepicker__navigation--years-previous,
.react-datepicker__month-year-option:hover .react-datepicker__navigation--years-previous {
  border-top-color: #b3b3b3; }

.react-datepicker__year-option--selected,
.react-datepicker__month-option--selected,
.react-datepicker__month-year-option--selected {
  position: absolute;
  left: 15px; }

.react-datepicker__close-icon {
  background-color: transparent;
  border: 0;
  cursor: pointer;
  outline: 0;
  padding: 0;
  vertical-align: middle;
  position: absolute;
  height: 16px;
  width: 16px;
  top: 25%;
  right: 7px; }

.react-datepicker__close-icon::after {
  background-color: #216ba5;
  border-radius: 50%;
  bottom: 0;
  box-sizing: border-box;
  color: #fff;
  content: "\00d7";
  cursor: pointer;
  font-size: 12px;
  height: 16px;
  width: 16px;
  line-height: 1;
  margin: -8px auto 0;
  padding: 2px;
  position: absolute;
  right: 0px;
  text-align: center; }

.react-datepicker__today-button {
  background: #f0f0f0;
  border-top: 1px solid #aeaeae;
  cursor: pointer;
  text-align: center;
  font-weight: bold;
  padding: 5px 0;
  clear: left; }

.react-datepicker__portal {
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.8);
  left: 0;
  top: 0;
  justify-content: center;
  z-index: 2147483647; }

.react-datepicker__portal .react-datepicker__day-name,
.react-datepicker__portal .react-datepicker__day,
.react-datepicker__portal .react-datepicker__time-name {
  width: 3rem;
  line-height: 3rem; }

@media (max-width: 400px), (max-height: 550px) {
  .react-datepicker__portal .react-datepicker__day-name,
  .react-datepicker__portal .react-datepicker__day,
  .react-datepicker__portal .react-datepicker__time-name {
    width: 2rem;
    line-height: 2rem; } }

.react-datepicker__portal .react-datepicker__current-month,
.react-datepicker__portal .react-datepicker-time__header {
  font-size: 1.44rem; }

.react-datepicker__portal .react-datepicker__navigation {
  border: 0.81rem solid transparent; }

.react-datepicker__portal .react-datepicker__navigation--previous {
  border-right-color: #ccc; }

.react-datepicker__portal .react-datepicker__navigation--previous:hover {
  border-right-color: #b3b3b3; }

.react-datepicker__portal .react-datepicker__navigation--previous--disabled, .react-datepicker__portal .react-datepicker__navigation--previous--disabled:hover {
  border-right-color: #e6e6e6;
  cursor: default; }

.react-datepicker__portal .react-datepicker__navigation--next {
  border-left-color: #ccc; }

.react-datepicker__portal .react-datepicker__navigation--next:hover {
  border-left-color: #b3b3b3; }

.react-datepicker__portal .react-datepicker__navigation--next--disabled, .react-datepicker__portal .react-datepicker__navigation--next--disabled:hover {
  border-left-color: #e6e6e6;
  cursor: default; }

/* pages */
.invitation {
  height: 100%; }
  .invitation .qrdefault {
    border: #a8a6a6 solid; }
  .invitation p {
    color: #000000;
    font-weight: normal;
    width: 350px;
    max-width: 100%;
    padding: 5px;
    background-color: #fff; }
  .invitation .loading-text {
    background-color: transparent; }
  .invitation b {
    color: #000000;
    font-weight: bolder; }
  .invitation .qr-container {
    width: 100%; }
  .invitation .qr {
    background-color: #fff;
    width: 350px;
    padding: 20px; }
  .invitation .imgQR {
    width: 350px; }
  .invitation .line-black {
    border-top: 2px solid #000000; }
  .invitation .fa-download {
    color: #000000; }
  .invitation .fa-spinner {
    color: #000000; }
  .invitation button {
    color: #000000;
    width: 350px;
    max-width: 100%; }
  .invitation .table-content {
    width: 350px;
    max-width: 100%; }
  .invitation .table-title {
    background: #fff;
    color: #000000;
    padding: 5px;
    font-weight: 900;
    max-width: 100%; }
  .invitation .table-text {
    background: #fff;
    color: #000000;
    padding: 3px;
    padding-left: 20px;
    font-weight: 700;
    line-height: 1.5;
    max-width: 100%; }

.complete {
  height: 100vh; }
  .complete header {
    background-color: rgba(234, 84, 95, 0); }
  .complete p {
    color: #000; }
  .complete .condition a {
    color: #ea4235;
    font-size: 0.93rem; }
  .complete b {
    color: #F5F4F4; }
  .complete button {
    border-radius: 30px; }
  .complete input {
    border-radius: 5px;
    background-color: rgba(245, 244, 244, 0); }
  .complete select {
    border-radius: 5px;
    background-color: rgba(245, 244, 244, 0); }
    .complete select option {
      background-color: #F5F4F4;
      border-radius: 10px; }
  .complete .line-black {
    border-top: 2px solid #F5F4F4; }
  .complete .fa-download {
    color: #000; }
  .complete .fa-spinner {
    color: #000; }
  .complete button {
    color: #F5F4F4; }

