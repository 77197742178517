$colorPrincipal : #000000;
$colorSec: #fff;

.invitation {

    height: 100%;

    .qrdefault {
        border: #a8a6a6 solid;
    }

    p {
        color: $colorPrincipal;
        font-weight: normal;
        width: 350px;
        max-width: 100%;
        padding: 5px;
        background-color: #fff;
    }

    .loading-text {
        background-color: transparent;
    }

    b {
        color: $colorPrincipal;
        font-weight: bolder;
    }

    .qr-container {
        width: 100%;
    }

    .qr {
        background-color: #fff;
        width: 350px;
        padding: 20px;
    }

    .imgQR {
        width: 350px;
    }

    .line-black {
        border-top: 2px solid $colorPrincipal;
    }

    .fa-download {
        color: $colorPrincipal;
    }

    .fa-spinner {
        color: $colorPrincipal;
    }

    button {
        color: $colorPrincipal;
        width: 350px;
        max-width: 100%;
    }

    .table-content {
        width: 350px;
        max-width: 100%;
    }

    .table-title {
        background: #fff;
        color: $colorPrincipal;
        padding: 5px;
        font-weight: 900;
        max-width: 100%;
    }

    .table-text {
        background: #fff;
        color: $colorPrincipal;
        padding: 3px;
        padding-left: 20px;
        font-weight: 700;
        line-height: 1.5;
        max-width: 100%;
    }
}