$colorPrincipal : #F5F4F4;
$colorSec: #4e4a4a;

.complete {

    height: 100vh;

    header {

        background-color: rgba(234, 84, 95, 0.00);

    }

    p {
        color: #000;
    }

    .condition {

        a {
            color: #ea4235;
            font-size: 0.93rem;
        }

    }

    b {
        color: #F5F4F4;
    }

    button {
        border-radius: 30px;
    }

    input {
        border-radius: 5px;
        background-color: rgba(245, 244, 244, 0);
    }

    select {
        border-radius: 5px;
        background-color: rgba(245, 244, 244, 0);

        option {
            background-color: #F5F4F4;
            border-radius: 10px;
        }
    }

    .line-black {
        border-top: 2px solid $colorPrincipal;
    }

    .fa-download {
        color: #000;
    }

    .fa-spinner {
        color: #000;
    }

    button {
        color: $colorPrincipal;
    }
}